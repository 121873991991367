.lesson-design-example {
  margin: 15px auto;
  max-width: 600px;
  padding: 0 20px;
  font-size: 18px;
}

.lesson-design-example p {
  font-size: 18px;
}

.lesson-design-example h3 {
  font-size: 24px;
  text-align: center;
}

.lesson-design-example h4 {
  font-size: 22px;
  text-align: center;
}

.lesson-design-example h5 {
  font-size: 20px;
  text-align: center;
}

.side-by-side {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .side-by-side {
    flex-direction: column;
  }
}

.side-by-side-text {
  flex: 1;
}

.side-by-side-image {
  border-style: solid;
  border-radius: 20px;
  border-width: 3px;
  border-color: #ddd;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px;
}

.image {
  width: 248px;
}

.gender-intro-image {
  height: 265px;
}

.here-is-my-coffee-image {
  height: 286px;
}

.introduce-moj-image {
  height: 244px;
}

.introduce-moja-image {
  height: 188px;
}

.introduce-moje-image {
  height: 152px;
}

.my-brother-image {
  height: 267px;
}

.my-father-image {
  height: 163px;
}

.my-mother-image {
  height: 125px;
}

.my-sister-image {
  height: 258px;
}

.neuter-nouns-image {
  height: 140px;
}

.new-nouns-image {
  height: 285px;
}

.that-is-not-my-radio-image {
  height: 269px;
}

.that-is-my-table-image {
  height: 291px;
}
