@font-face {
  font-family: 'PublicSans';
  src: local('PublicSans'), url(../../fonts/PublicSans-VariableFont_wght.ttf) format('truetype');
}

tr {
  height: 40px;
}

.included {
  color: rgb(6, 160, 65);
}

.location-selector {
  font-size: 18px;
  font-family: 'PublicSans', sans-serif;
  width: 200px;
}

.location-selector-container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 10px auto;
  position: relative;
  width: 100%;
}

.location-selector-area-left {
  width: 40%;
}

.location-selector-area-right {
  width: 60%;
}

.location-selector-label-text {
  font-size: 18px;
  font-family: 'PublicSans', sans-serif;
  margin: 0px 15px;
  text-align: right;
}

.not-included {
  color: rgba(235, 88, 88);
}

.note {
  font-size: 12px;
  font-weight: 400;
  margin: 0px 0px 10px 0px;
}

.note-balance {
  color: rgb(255, 255, 255, 0);
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.plan-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  border: 1px solid #ddd;
  font-size: 18px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.plans-and-pricing {
  margin: 15px auto;
  max-width: 600px;
  padding: 0 20px;
  font-size: 18px;
}

.plans-and-pricing h3 {
  font-size: 24px;
  text-align: center;
}

.plans-and-pricing h4 {
  font-size: 22px;
  text-align: center;
}

.plans-and-pricing h5 {
  font-size: 20px;
  text-align: center;
}

.price {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 5px;
}

.restricted {
  color: rgb(168, 110, 56);
}

.trial {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
}
