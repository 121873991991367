.tips-word-order {
  max-width: 800px;
  align-items: center;

}

.tips-word-order h3 {
  align-self: center;
  font-size: 24px;
  text-align: center;
}

.tips-word-order .return {
  text-align: center;
  font-size: 20px;
}
