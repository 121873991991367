.privacy-policy {
  margin: 15px auto;
  max-width: 600px;
  padding: 0 20px;
  font-size: 18px;
}

.privacy-policy p {
  font-size: 18px;
}

.privacy-policy h3 {
  font-size: 24px;
}

.privacy-policy h4 {
  font-size: 22px;
}

.privacy-policy h5 {
  font-size: 20px;
}

.privacy-policy h6 {
  font-size: 18px;
  margin: 10px 0px;
}