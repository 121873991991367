.updates {
  margin: 15px auto;
  max-width: 600px;
  padding: 0 20px;
  font-size: 18px;
}

.updates p {
  font-size: 18px;
}

.updates h3 {
  font-size: 24px;
  text-align: center;
}

.updates h4 {
  font-size: 22px;
  text-align: center;
}