.tips-greetings {
  max-width: 800px;
  align-items: center;
}

h3 {
  align-self: center;
  font-size: 25px;
  text-align: center;
}

h4 {
  align-self: center;
  font-size: 23px;
  text-align: center;
}

.contents {
  text-align: center;
  font-size: 19px;
}

.tips-greetings .lede {
  align-self: center;
  font-size: 19px;
  text-align: left;
  width: 100%;
}

.tips-greetings .explanation {
  align-self: center;
  font-size: 19px;
  text-align: left;
  width: 100%;
}

.pronunciation-tip {
  align-self: center;
  background-color: #ddffe0;
  border-style: solid;
  border-color: #00aa22;
  border-radius: 30px;
  border-width: 1px;
  box-shadow: 0px 2px 2px lightgray;
  color: #004409;
  font-size: 18px;
  margin: 20px;
  padding: 20px;
  text-align: center;
}

.background-tip {
  align-self: center;
  background-color: #e0e0ff;
  border-style: solid;
  border-color: #0000aa;
  border-radius: 30px;
  border-width: 1px;
  box-shadow: 0px 2px 2px lightgray;
  color: #000044;
  font-size: 18px;
  margin: 20px;
  padding: 20px;
  text-align: center;
}

h5 {
  margin: 0;
}

.pronunciation-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.background-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button {
  background-color: #333333;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  outline: 0;
  border: 0;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  &:hover {
    background-color: #000000;
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
}
