.lesson-design {
  margin: 15px auto;
  max-width: 600px;
  padding: 0 20px;
  font-size: 18px;
}

.lesson-design p {
  font-size: 18px;
}

.lesson-design h3 {
  font-size: 24px;
  text-align: center;
}

.lesson-design h4 {
  font-size: 22px;
  text-align: center;
}

.lesson-design h5 {
  font-size: 20px;
  text-align: center;
}
