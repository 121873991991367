.home {
  max-width: 800px;
}

.phraseforge-slovak-graphic {
  margin-bottom: 15px;
  margin-top: 15px;
  max-width: 300px;
  top: 0;
  width: 300px;
  height: 146px;
}

.app-store-badge {
  height: 48px;
  width: 142px;
  padding-right: 5px;
}

.play-store-badge {
  height: 48px;
  width: 162px;
  padding-left: 5px;
}

.lede {
  font-size: 19.5px;
  text-align: center;
  padding: 0px 20px;
}

.intro {
  align-items: center;
  display: flex;
  flex-direction: column;
}

h2.lede {
  padding: 0px 0 20px 0;
}

.section {
  display: flex;
  flex-direction: row;
}

.reverse {
  flex-direction: row-reverse;
}

.caption {
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 60px;
}

@media screen and (max-width: 900px) {
  .section {
    align-content: center;
    align-items: center;
    flex-direction: column;
  } 

  .caption {
    max-width: 300px;
    padding: 5px 0;
  }
}

.screenshot {
  width: auto;
  height: auto;
  border-color: #DDDDDD;
  border-radius: 15px;
  border-style: solid;
  width: 270px;
  height: 412.5px;

}

.section .caption h3 {
  font-size: 19px;
  text-align: center;
}

.section .caption p {
  font-size: 18px;
}

.disclaimer {
  font-size: 11px;
  text-align: center;
}