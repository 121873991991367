.tips {
  max-width: 800px;
  align-items: center;

}

.tips h3 {
  align-self: center;
  font-size: 24px;
  text-align: center;
}

.tips .link {
  list-style-type: none;
  align-self: center;
  font-size: 20px;
  text-align: center;
}