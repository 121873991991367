.dropdown-content {
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  justify-content: center;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2;
}

.dropdown-item {
  background-color: #dceef8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 30px;
  text-align: center;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #a3cde4;
  cursor: pointer;
}

.nav {
  align-content: stretch;
  background-color: #dceef8;
  border-color: #a2b3d6;
  border-radius: 40px;
  border-style: solid;
  border-width: 2px;
  display: flex;
  flex-wrap: wrap;
  max-width: 750px;
  width: 100%;
}

.nav-cap {
  width: 20px;
}

.nav-item {
  color: #263e8f;
  flex-grow: 1;
  height: 35px;
  position: relative;

}

.nav-interior {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 20px;
  justify-content: center;
  height: 100%;
  padding: 0px 10px;
  text-align: center;
  text-decoration: none;
}

.nav-item:hover {
  background-color: #a3cde4;
  cursor: pointer;
}

.active {
  background-color: #b5dfe6;
}

@media (max-width: 750px) {
  .nav {
    border-radius: 0px;
  }
  .nav-cap {
    width: 0px;
  }
}
