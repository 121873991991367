.course-design {
  margin: 15px auto;
  max-width: 600px;
  padding: 0 20px;
  font-size: 18px;
}

.course-design p {
  font-size: 18px;
}

.course-design h3 {
  font-size: 24px;
  text-align: center;
}

.course-design h4 {
  font-size: 22px;
  text-align: center;
}

.course-design h5 {
  font-size: 20px;
  text-align: center;
}

.course-design-intro {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (max-width: 600px) {
  .course-design-intro {
    flex-direction: column;
  }
}

.course-design-intro-text {
  flex: 1;
}

.course-design-intro-image {
  height: 442px;
  width: 248px;
}