@font-face {
  font-family: 'PublicSans';
  src: local('PublicSans'), url(../../fonts/PublicSans-VariableFont_wght.ttf) format('truetype');
}

.page {
  align-items: center;
  background-color: white;
  color: #282c34;
  display: flex;
  flex-direction: column;
  font-family: 'PublicSans';
  font-size: calc(10px + 2vmin);
  height: 100%;
  justify-content: flex-start;
  min-height: 100vh;
  position: relative;
}

.banner {
  align-items: center;
  background: linear-gradient(180deg, #80d2e0, white);
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
}

.banner-graphic {
  margin-bottom: 15px;
  margin-top: 15px;
  max-width: 300px;
  top: 0;
  width: 300px;
  height: 126px;
}

footer {
  align-items: center;
  background: linear-gradient(0deg, #80d2e0, white);
  bottom: 0;
  column-gap: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: center;
  left: 0;
  padding: 10px;
  position: relative;
  right: 0;
  row-gap: 5px;
  text-align: center;
  width: 100%;
}

.footer-nav {
  align-content: center;
  color: #263e8f;
  flex-grow: 1;
  font-size: 14px;
  height: 40px;
  text-align: center;
  text-decoration: none;
}

main {
  flex: 1;
}
