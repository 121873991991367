.tips-pronunciation {
  max-width: 800px;
  align-items: center;

}

.tips-greetings h3 {
  align-self: center;
  font-size: 24px;
  text-align: center;
}

.tips-pronunciation .return {
  text-align: center;
  font-size: 20px;
}
