.contact-us {
  margin: 15px auto;
  max-width: 600px;
  padding: 0 20px;
  font-size: 18px;
}

.contact-us p {
  font-size: 18px;
}

.contact-us h3 {
  font-size: 24px;
}

.contact-us h4 {
  font-size: 22px;
}

.contact-us h5 {
  font-size: 20px;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.removal-details p {
  font-size: 15px;
}