.terms-of-use {
  margin: 15px auto;
  max-width: 600px;
  padding: 0 20px;
  font-size: 18px;
}

.terms-of-use p {
  font-size: 18px;
}

.terms-of-use h3 {
  font-size: 24px;
}

.terms-of-use h4 {
  font-size: 22px;
}

.terms-of-use h5 {
  font-size: 20px;
}

.terms-of-use h6 {
  font-size: 18px;
  margin: 10px 0px;
}